input[type="text"]
{
  font-size:24px;


}


html, body {
  overflow-x: hidden;
}
body {
  position: relative;
  width: 100%;
}

.input{
  height: 3em;
  text-align: center;
}



.bg{
  background-color: lightskyblue;
  font-size: larger;
}

.cosa {
  font-size: 20px;
  font-weight: bold;
}
.cert{
  background-image: url("/public/certificato.png");
  width: 2000px;
  height: 1400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}


.nome{
  font-size: 10em;
  color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
